import { Box, type TableCellProps } from '@mui/material';
import { type PodcastType } from '@prisma/client';
import { defineMessage, type MessageDescriptor, type IntlShape } from 'react-intl';
import { ActivePodcasts } from 'components/ActivePodcasts';
import { FormattedNumber } from 'components/FormattedNumber';
import { ImageFade } from 'components/ImageFade';
import { RankChange as RankChangeComponent } from 'components/RankChange';
import { localeCompare } from 'helpers/locale';
import { compareNumber, compareString } from 'helpers/sort';
import { getPodcastImageUrl } from 'helpers/urls';
import { type ReportDemosPlusPodcastAu, type Podcast, type RowCompanies } from 'report/getReport';
import {
    type DemoPlusPodcastCharacteristicHeader,
    type DemosPlusCsvCharacteristic,
    allDemosPlusHeaderMessageMap,
    type RankChange,
} from 'report/reportTypes';
import { theme } from 'styles/mui-theme';

export type TableColumnPdfCell =
    | string
    | {
          value: string;
          style: {
              textColor?: string;
              horizontalAlign?: 'left' | 'center' | 'right';
          };
      };

export type TableColumnPdf<Row> = {
    headerMessageDescriptor: MessageDescriptor;
    renderCellPdf: (row: Row, intl: IntlShape) => TableColumnPdfCell;
};

export type TableColumn<Row> = TableColumnPdf<Row> & {
    align: TableCellProps['align'] | undefined;
    renderCell: (row: Row) => React.ReactNode;
    sortAscendingFn: (a: Row, b: Row) => number;
    tooltip?: MessageDescriptor;
};

export const activePodcastsTableColumn: TableColumn<{ activePodcasts: number }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Active podcasts',
        description: 'Table column header for active podcasts',
    }),
    align: 'right',
    renderCell: (row) => <ActivePodcasts value={row.activePodcasts} />,
    renderCellPdf: (row, intl) => {
        return {
            value: intl.formatNumber(row.activePodcasts) + `${row.activePodcasts === 500 ? '+' : ''} `,
            style: { horizontalAlign: 'right' },
        };
    },
    sortAscendingFn: (a, b) => compareNumber(a.activePodcasts, b.activePodcasts),
};

export const averageWeeklyDownloadsTableColumn: TableColumn<{ averageWeeklyDownloads: number }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Avg weekly downloads',
        description: 'Table column header for average weekly downloads',
    }),
    align: 'right',
    renderCell: (row) => <FormattedNumber value={row.averageWeeklyDownloads} />,
    renderCellPdf: (row, intl) => {
        return {
            value: intl.formatNumber(row.averageWeeklyDownloads),
            style: { horizontalAlign: 'right' },
        };
    },
    sortAscendingFn: (a, b) => compareNumber(a.averageWeeklyDownloads, b.averageWeeklyDownloads),
};

export const averageWeeklyUsersTableColumn: TableColumn<{ averageWeeklyUsers: number }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Avg weekly users',
        description: 'Table column header for average weekly users',
    }),
    align: 'right',
    renderCell: (row) => <FormattedNumber value={row.averageWeeklyUsers} />,
    renderCellPdf: (row, intl) => {
        return {
            value: intl.formatNumber(row.averageWeeklyUsers),
            style: { horizontalAlign: 'right' },
        };
    },
    sortAscendingFn: (a, b) => compareNumber(a.averageWeeklyUsers, b.averageWeeklyUsers),
};

export const monthlyDownloadsTableColumn: TableColumn<{ monthlyDownloads: number }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Monthly downloads',
        description: 'Table column header for monthly downloads',
    }),
    align: 'right',
    renderCell: (row) => <FormattedNumber value={row.monthlyDownloads} />,
    renderCellPdf: (row, intl) => {
        return {
            value: intl.formatNumber(row.monthlyDownloads),
            style: { horizontalAlign: 'right' },
        };
    },
    sortAscendingFn: (a, b) => compareNumber(a.monthlyDownloads, b.monthlyDownloads),
};

export const monthlyListenersTableColumn: TableColumn<{ monthlyListeners: number }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Monthly listeners',
        description: 'Table column header for monthly listeners',
    }),
    align: 'right',
    renderCell: (row) => <FormattedNumber value={row.monthlyListeners} />,
    renderCellPdf: (row, intl) => {
        return {
            value: intl.formatNumber(row.monthlyListeners),
            style: { horizontalAlign: 'right' },
        };
    },
    sortAscendingFn: (a, b) => compareNumber(a.monthlyListeners, b.monthlyListeners),
};

export const networkTableColumn: TableColumn<{ networks: RowCompanies }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Network',
        description: 'Table column header for network',
    }),
    align: undefined,
    renderCell: (row) => row.networks.name,
    renderCellPdf: (row) => row.networks.name,
    sortAscendingFn: (a, b) => compareString(a.networks.name, b.networks.name),
};

export const podcastNameTableColumn: TableColumn<{
    podcast: Podcast;
}> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Podcast',
        description: 'Table column header for podcast',
    }),
    align: undefined,
    renderCell: ({ podcast }) => {
        return (
            <div
                css={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <PodcastImage podcast={podcast} />
                <span>{podcast.title}</span>
            </div>
        );
    },
    renderCellPdf: ({ podcast }) => podcast.title,
    sortAscendingFn: (a, b) => compareString(a.podcast.title, b.podcast.title),
};

export const podcastTypeTableColumn: TableColumn<{ podcastType: PodcastType }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Podcast Type',
        description: 'Table column header for podcast type',
    }),
    align: undefined,
    renderCell: (row) => formatPodcastTypeDisplay(row.podcastType),
    renderCellPdf: (row) => formatPodcastTypeDisplay(row.podcastType),
    sortAscendingFn: (a, b) => compareString(a.podcastType, b.podcastType),
};

export const podcastNameWithPublisherTableColumn: TableColumn<{
    podcast: Podcast;
    publishers: RowCompanies;
}> = {
    headerMessageDescriptor: podcastNameTableColumn.headerMessageDescriptor,
    align: podcastNameTableColumn.align,
    renderCell: ({ podcast, publishers }) => {
        return (
            <div
                css={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <PodcastImage podcast={podcast} />
                <div>
                    <div>{podcast.title}</div>
                    <div
                        css={{
                            color: theme.tritonColors.gray,
                        }}
                    >
                        {publishers.name}
                    </div>
                </div>
            </div>
        );
    },
    renderCellPdf: ({ podcast, publishers }) => `${podcast.title} (${publishers.name})`,
    sortAscendingFn: podcastNameTableColumn.sortAscendingFn,
};

export const publisherTableColumn: TableColumn<{ publishers: RowCompanies }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Publisher',
        description: 'Table column header for publisher',
    }),
    align: undefined,
    renderCell: (row) => {
        return row.publishers.name;
    },
    renderCellPdf: (row) => row.publishers.name,
    sortAscendingFn: (a, b) => compareString(a.publishers.name, b.publishers.name),
};

export const publishersTableColumn: TableColumn<{ publishers: RowCompanies }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Publishers represented',
        description: 'Table column header for publishers represented',
    }),
    align: undefined,
    renderCell: (row) => {
        return row.publishers.name;
    },
    renderCellPdf: (row) => row.publishers.name,
    sortAscendingFn: (a, b) => compareString(a.publishers.name, b.publishers.name),
};

export const rankTableColumn: TableColumn<{ rank: number; rankChange: RankChange }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Rank',
        description: 'Table column header for rank',
    }),
    align: undefined,
    renderCell: (row) => {
        return (
            <div
                css={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <div css={{ fontSize: '1.3em' }}>{row.rank}</div>
                <RankChangeComponent rankChange={row.rankChange} />
            </div>
        );
    },
    renderCellPdf: (row, intl) => {
        return {
            value: intl.formatNumber(row.rank),
            style: { horizontalAlign: 'center' },
        };
    },
    sortAscendingFn: (a, b) => compareNumber(a.rank, b.rank),
};

export const salesNetworkTableColumn: TableColumn<{ networks: RowCompanies }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Sales network',
        description: 'Table column header for sales network',
    }),
    align: undefined,
    renderCell: (row) => row.networks.name,
    renderCellPdf: (row) => row.networks.name,
    sortAscendingFn: (a, b) => compareString(a.networks.name, b.networks.name),
};

export const salesRepresentativeTableColumn: TableColumn<{ salesRepresentatives: RowCompanies }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Sales representation',
        description: 'Table column header for sales representation',
    }),
    align: undefined,
    renderCell: (row) => {
        return row.salesRepresentatives.name;
    },
    renderCellPdf: (row) => row.salesRepresentatives.name,
    sortAscendingFn: (a, b) => compareString(a.salesRepresentatives.name, b.salesRepresentatives.name),
};

function PodcastImage({ podcast }: { podcast: Podcast }) {
    return (
        <Box
            width={40}
            height={40}
            css={{
                background: podcast.imageDominantColor,
                marginRight: 10,
                flexShrink: 0,
                [theme.breakpoints.down('md')]: {
                    width: 32,
                    height: 32,
                },
            }}
        >
            <ImageFade src={getPodcastImageUrl(podcast)} alt={podcast.title} width="100%" height="100%" />
        </Box>
    );
}

export function getDemosPlusCharacteristicColumns(
    key: DemoPlusPodcastCharacteristicHeader,
): TableColumn<{ characteristics: DemosPlusCsvCharacteristic }> {
    return {
        headerMessageDescriptor:
            Object.entries(allDemosPlusHeaderMessageMap).find(([k]) => k === key)?.[1] ??
            defineMessage({ defaultMessage: 'Characteristic', id: 'characteristic.fallback' }), // Normally we will not get there because of the type safety
        align: 'right',
        renderCell: (row) => <>{row.characteristics[key]}</>,
        renderCellPdf: (row) => ({
            value: `${row.characteristics[key]}`,
            style: { horizontalAlign: 'right' },
        }),
        sortAscendingFn: (a, b) => compareNumber(a.characteristics[key] ?? 0, b.characteristics[key] ?? 0),
        tooltip: defineMessage({
            defaultMessage:
                'Index (Monthly Podcast pop 18+): Compares % of a podcast’s audience against the overall Australian monthly podcast listener population (age 18+). Eg: Index 125 “Category X is 25% more likely than average monthly podcast listener to do purchase a new car.',
            description: 'Tooltip for DemosPlus characteristic columns',
        }),
    };
}

export const categoryTableColumn: TableColumn<{ category: string | null }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Category',
        description: 'Table column header for category',
    }),
    align: 'right',
    renderCell: (row) => <>{row.category}</>,
    renderCellPdf: (row) => {
        return {
            value: row.category ? row.category : '-',
            style: { horizontalAlign: 'left' },
        };
    },
    sortAscendingFn: (a, b) => compareString(a.category ?? '', b.category ?? ''),
};

// Only for PDF export
export const rankChangeTableColumn: TableColumnPdf<{ rankChange: RankChange }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Change',
        description: 'Table column header for rank change',
    }),
    renderCellPdf: (row) => {
        switch (row.rankChange.type) {
            case 'noChange': {
                return {
                    value: '-',
                    style: { horizontalAlign: 'center' },
                };
            }

            case 'debut': {
                return {
                    value: 'Debut',
                    style: { horizontalAlign: 'center' },
                };
            }

            case 'return': {
                return {
                    value: 'Return',
                    style: { horizontalAlign: 'center' },
                };
            }

            case 'up': {
                return {
                    value: '+' + row.rankChange.change,
                    style: { textColor: '#22c55e', horizontalAlign: 'center' },
                };
            }

            case 'down': {
                return {
                    value: row.rankChange.change.toString(),
                    style: { textColor: '#ef4444', horizontalAlign: 'center' },
                };
            }
        }
    },
};

export const newEpisodesTableColumn: TableColumnPdf<{ newEpisodes: number }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'New episodes',
        description: 'Table column header for new episodes',
    }),
    renderCellPdf: (row, intl) => {
        return {
            value: intl.formatNumber(row.newEpisodes),
            style: { horizontalAlign: 'right' },
        };
    },
};

export const categoryTableColumnPdf: TableColumnPdf<{ category: string | null }> = {
    headerMessageDescriptor: defineMessage({
        defaultMessage: 'Category',
        description: 'Table column header for category',
    }),
    renderCellPdf: (row) => {
        return {
            value: row.category ? row.category : '-',
            style: { horizontalAlign: 'left' },
        };
    },
};

export function getDemosPlusPdfCharacteristicColumns(
    key: DemoPlusPodcastCharacteristicHeader,
): TableColumnPdf<{ characteristics: DemosPlusCsvCharacteristic }> {
    return {
        headerMessageDescriptor:
            Object.entries(allDemosPlusHeaderMessageMap).find(([k]) => k === key)?.[1] ??
            defineMessage({ defaultMessage: 'Characteristic', id: 'characteristic.fallback' }), // Normally we will not get there because of the type safety
        renderCellPdf: (row) => {
            return {
                value: `${row.characteristics[key]}`,
                style: { horizontalAlign: 'right' },
            };
        },
    };
}

export function getDemoPlusColumnHeader(rows: ReportDemosPlusPodcastAu['rows']) {
    if (!rows.length) {
        return [];
    }
    const columnOptions = Object.keys(rows[0].characteristics).sort(localeCompare);

    return columnOptions;
}

export const formatPodcastTypeDisplay = (value: string): string => {
    switch (value) {
        case 'podcast':
            return 'Podcast';
        case 'audioOnDemand':
            return 'Audio On Demand';
        default:
            return 'N/A';
    }
};
